.swiper-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: 229px;
  left: 0;
}
.swiper-buttons img {
  cursor: pointer;
  z-index: 10;
}

@media screen and (max-width: 1200px) {
  .swiper-buttons {
    width: 90%;
    left: 48px;
  }
}
@media screen and (max-width: 1100px) {
  .swiper-buttons {
    display: none;
  }
}/*# sourceMappingURL=index.css.map */