.header {
    width: 100%;
    height: 100px;
    background: rgba(0, 0, 0, .5);
    position: sticky;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    top: 0;
    left: 0;
}

.header__logo {
    width: 180px;
    height: 60px;
}

.header__logo-container {
    width: 424px;
}

.header__button {
    width: 180px;
    font-size: 18px;
    height: 40px;
    margin-left: 30px;
    color: #fff;
}

.header__container {
    width: 700px;
    display: flex;
    justify-content: space-between;
}

.header__link {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    transition: 0.4s ease-in-out;
    cursor: pointer;

    &:hover {
        color: #c0c0c0;
    }
}

.header__button-text {
    color: #fff;
    text-decoration: none;
    transition: 0.4s ease-in-out;
    cursor: pointer;

    &:hover {
        color: #c0c0c0;
    }
}

.header__buttons {
    display: flex;
    align-items: center;
}

.header__whatsapp {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.header__button-news {
    display: none;
}

.header__contact {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter';
    font-weight: 200;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}

@media screen and (max-width: 1650px) {
    .header__logo-container {
        width: 300px;
        font-size: 40px;
    }
}

@media screen and (max-width: 1450px) {
    .header__logo-container {
        width: 200px;
        font-size: 40px;
    }
}

@media screen and (max-width: 1400px) {
    .header__contact {
        display: none;
    }
}

@media screen and (max-width: 1550px) {
    .header__container {
        width: 600px;
    }
}


@media screen and (max-width: 1100px) {
    .header__container {
        width: 450px;
    }
}

@media screen and (max-width: 1100px) {
    .header__link {
        font-size: 15px;
    }
}


@media screen and (max-width: 950px) {
    .header__container {
        display: none;
    }
}

@media screen and (max-width: 950px) {
    .header__button-news {
        display: block;
    }
}

@media screen and (max-width: 700px) {
    .header__button {
        width: 130px;
        font-size: 13px;
        height: 30px;
    }
}

@media screen and (max-width: 700px) {
    .header__button {
        width: 130px;
        font-size: 13px;
        height: 30px;
    }
}


@media screen and (max-width: 550px) {
    .header__button {
        width: 110px;
        font-size: 11px;
        height: 25px;
        border-radius: 10px;
    }
}


@media screen and (max-width: 550px) {
    .header__buttons {
        flex-direction: column;
        justify-content: space-between;
        height: 60px;
    }
}

@media screen and (max-width: 500px) {
    .header__logo {
        width: 130px;
        object-fit: contain;
        height: 60px;
    }
}

@media screen and (max-width: 500px) {
    .header {
        padding: 10px;
        height: 80px;
    }
}
