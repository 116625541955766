.video {
  position: absolute;
  z-index: 0;
}

.video-bg {
  width: inherit;
  height: inherit;
  background-color: #000;
  opacity: 0.5;
  z-index: 1;
  position: absolute;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 1080px;
  max-width: 1920px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=index.css.map */