.services {
  background-color: #1a1a1a;
  padding-top: 100px;
  margin-top: 100px;
  padding-bottom: 100px;
}

.services__title {
  font-size: 72px;
  font-weight: 800;
  display: flex;
  margin-bottom: 100px;
  justify-content: center;
}

.services__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  -moz-column-gap: 80px;
       column-gap: 80px;
  row-gap: 80px;
}

.services__item {
  width: 300px;
  height: 500px;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.services__item:hover {
  transform: scale(1.05);
}

.services__image {
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}

.services__item-container {
  position: absolute;
  z-index: 20;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  bottom: 0;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
}

.services__item-title {
  font-size: 28px;
  font-weight: 600;
  width: 90%;
  text-align: center;
}

.services__item-price {
  font-size: 30px;
  color: #d2d2d2;
  font-weight: 200;
}

.services__item-button {
  width: 220px;
  height: 50px;
  font-size: 24px;
}

@media screen and (max-width: 1100px) {
  .services__title {
    font-size: 65px;
  }
}
@media screen and (max-width: 850px) {
  .services__title {
    font-size: 55px;
  }
}
@media screen and (max-width: 750px) {
  .services__title {
    font-size: 48px;
  }
}
@media screen and (max-width: 650px) {
  .services__title {
    font-size: 40px;
  }
}
@media screen and (max-width: 550px) {
  .services__title {
    font-size: 35px;
  }
}
@media screen and (max-width: 450px) {
  .services__title {
    font-size: 30px;
  }
}
@media screen and (max-width: 400px) {
  .services__title {
    font-size: 25px;
  }
}
@media screen and (max-width: 750px) {
  .services__title {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 700px) {
  .services__item-title {
    font-size: 25px;
  }
}
@media screen and (max-width: 700px) {
  .services__item-price {
    font-size: 25px;
  }
}
@media screen and (max-width: 750px) {
  .services {
    padding-top: 50px;
    margin-top: 50px;
    padding-bottom: 50px;
  }
}/*# sourceMappingURL=index.css.map */