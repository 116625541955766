.modal-success {
  width: 200px;
  height: 80px;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 5;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  left: 0;
  right: 0;
  top: 150px;
  text-align: center;
  background-color: #212121;
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  border-radius: 8px;
  box-shadow: 20px 20px 22px rgba(0, 0, 0, 0.2);
  color: white;
  align-items: center;
  z-index: 50;
  opacity: 0;
  transition: 0.5s ease-in-out;
  margin-top: -200px;
}

.modal-success.active {
  opacity: 1;
  margin-top: 0;
}/*# sourceMappingURL=index.css.map */