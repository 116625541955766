@keyframes rotation {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 360deg;
    }
}

.about__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    padding-right: 200px;
    padding-left: 200px;
    padding-bottom: 350px;
    background-color: #1a1a1a;
}

.about__title {
    margin-top: 100px;
    margin-bottom: 30px;
    font-size: 72px;
    font-weight: 800;
}

.about__text {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 30px;
    width: 1000px;
}

.about__button {
    width: 180px;
    font-size: 18px;
    height: 40px;
}

.swiper {
    margin-top: -270px;
    width: 1400px;
    padding-left: 200px !important;
    padding-right: 200px !important;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: grab;
}

.about__image {
    border-radius: 30px;
}


.about__advantages {
    display: flex;
    width: 75%;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 50px;
    color: #fff;
}

.about__advantages-container {
    display: flex;
    flex-direction: column;
    width: 67%;
}

.about__advantages-image {
    width: 360px;
    height: 530px;
}

.about__advantages-title {
    font-size: 72px;
    font-weight: 800;
    margin-bottom: 30px;
}

.about__advantages-text {
    font-size: 24px;
    font-weight: 200;
}

.about__advantages-items {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 377px);
    grid-row-gap: 50px;
    grid-column-gap: 15px;
}

.about__advantages-item {
    display: flex;
    cursor: pointer;
    &:hover .about__advantages-item-image {
        animation: rotation 0.5s;
    }
    &:hover .about__more {
        height: 30px;
        opacity: 1;
    }
}

.about__advantages-item-image {
    width: 75px;
    height: 75px;
}

.about__advantages-item-container {
    margin-left: 10px;
}

.about__advantages-item-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
}

.about__advantages-item-text {
    font-size: 16px;
    font-weight: 300;
    color: #a7a7a7;
}

.about__more {
    height: 0;
    opacity: 0;
    transition: 0.3s ease-in-out;
    font-size: 16px;
    color: #ffffff;
}

.swiper-button-next,
.swiper-button-prev {
    display: none !important;
}

@media screen and (max-width: 1520px) {
    .about__advantages-image {
        display: none;
    }
}

@media screen and (max-width: 1520px) {
    .about__advantages-container {
        align-items: center;
        text-align: center;
        width: 100%;
    }
}


@media screen and (max-width: 1520px) {
    .swiper {
        width: 1200px;
        padding-left: 100px !important;
        padding-right: 100px !important;
    }
}


@media screen and (max-width: 1200px) {
    .swiper {
        width: 1000px;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media screen and (max-width: 1100px) {
    .swiper {
        width: 90%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media screen and (max-width: 1100px) {
    .about__title {
        width: 90%;
    }
}

@media screen and (max-width: 1100px) {
    .about__text {
        width: 90%;
    }
}

@media screen and (max-width: 1200px) {
    .about__advantages {
        width: 90%;
    }
}

@media screen and (max-width: 850px) {
    .about__advantages-items {
        grid-template-columns: repeat(1, 500px);
    }
}

@media screen and (max-width: 850px) {
    .about__advantages-item {
        text-align: left;
    }
}

@media screen and (max-width: 1100px) {
    .about__container {
        padding-left: 0;
        padding-right: 0;
    }
}


@media screen and (max-width: 1100px) {
    .about__title {
        font-size: 65px;
    }
}


@media screen and (max-width: 850px) {
    .about__title {
        font-size: 55px;
    }
}

@media screen and (max-width: 750px) {
    .about__title {
        font-size: 48px;
        margin-top: 50px;
    }
}



@media screen and (max-width: 650px) {
    .about__title {
        font-size: 40px;
    }
}


@media screen and (max-width: 550px) {
    .about__title {
        font-size: 35px;
    }
}



@media screen and (max-width: 450px) {
    .about__title {
        font-size: 30px;
    }
}


@media screen and (max-width: 400px) {
    .about__title {
        font-size: 25px;
    }
}

@media screen and (max-width: 1100px) {
    .about__advantages-title {
        font-size: 65px;
    }
}


@media screen and (max-width: 850px) {
    .about__advantages-title {
        font-size: 55px;
    }
}

@media screen and (max-width: 750px) {
    .about__advantages-title {
        font-size: 48px;
    }
}



@media screen and (max-width: 650px) {
    .about__advantages-title {
        font-size: 40px;
    }
}


@media screen and (max-width: 550px) {
    .about__advantages-title {
        font-size: 35px;
    }
}



@media screen and (max-width: 450px) {
    .about__advantages-title {
        font-size: 30px;
    }
}


@media screen and (max-width: 400px) {
    .about__advantages-title {
        font-size: 25px;
        margin-top: 30px;
    }
}

@media screen and (max-width: 850px) {
    .about__text {
        font-size: 20px;
    }
}


@media screen and (max-width: 650px) {
    .about__text {
        font-size: 15px;
    }
}


@media screen and (max-width: 400px) {
    .about__text {
        font-size: 13px;
    }
}

@media screen and (max-width: 850px) {
    .about__advantages-text {
        font-size: 20px;
    }
}


@media screen and (max-width: 650px) {
    .about__advantages-text {
        font-size: 15px;
    }
}


@media screen and (max-width: 400px) {
    .about__advantages-text {
        font-size: 13px;
    }
}

@media screen and (max-width: 650px) {
    .about__advantages-item-title {
        font-size: 20px;
    }
}

@media screen and (max-width: 400px) {
    .about__advantages-item-title {
        font-size: 18px;
    }
}

@media screen and (max-width: 650px) {
    .about__advantages-item-text {
        font-size: 15px;
    }
}




@media screen and (max-width: 850px) {
    .swiper {
        margin-top: -200px;
    }
}

@media screen and (max-width: 850px) {
    .about__container {
        padding-bottom: 250px;
    }
}

@media screen and (max-width: 550px) {
    .about__advantages-items {
        grid-template-columns: repeat(1, 400px);
    }
}

@media screen and (max-width: 450px) {
    .about__advantages-items {
        grid-template-columns: repeat(1, 350px);
    }
}

@media screen and (max-width: 400px) {
    .about__advantages-items {
        grid-template-columns: repeat(1, 300px);
    }
}

@media screen and (max-width: 450px) {
    .about__advantages-item-image {
        width: 50px;
        height: 50px;
    }
}

@media screen and (max-width: 600px) {
    .about__more {
        font-size: 14px;
    }
}
