.posts__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
}

.posts__button {
    margin-top: 50px;
    font-size: 19px;
    font-weight: 300;
}

