.button {
  font-size: 24px;
  width: 290px;
  height: 70px;
  background: linear-gradient(#e63732, #e85931);
  border-radius: 20px;
  color: #fff;
  box-shadow: 0 0 0.5em 0 #e63732;
  text-shadow: 0 0 0.9em #fff;
  transition: 0.3s ease-in-out;
}
.button:hover {
  box-shadow: 0 0 0.8em 0 #e63732;
  text-shadow: 0 0 0.3em #fff;
}/*# sourceMappingURL=index.css.map */