.post {
  display: flex;
  flex-direction: column;
  width: 700px;
  margin-top: 40px;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.post-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
}

.post-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.post-title {
  font-size: 13px;
  margin: 0;
  color: #71aaeb;
  font-weight: 600;
  margin-bottom: 5px;
}

.post-date {
  font-size: 12.5px;
  font-weight: 500;
  color: #828282;
  margin: 0;
}

.post-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.post-text {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  word-break: break-word;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 20px;
}
.post-text p {
  margin: 5px;
  min-height: 20px;
}

.post-image {
  width: 100%;
  border-radius: 20px;
}

@media screen and (max-width: 730px) {
  .post {
    width: 450px;
  }
}
@media screen and (max-width: 730px) {
  .post-text {
    font-size: 11px;
  }
}
@media screen and (max-width: 480px) {
  .post {
    width: 300px;
  }
}
@media screen and (max-width: 730px) {
  .post-title {
    font-size: 11px;
    line-height: 15px;
  }
}
@media screen and (max-width: 730px) {
  .post-date {
    font-size: 12.5px;
  }
}
@media screen and (max-width: 730px) {
  .post-text {
    font-size: 9px;
  }
}/*# sourceMappingURL=index.css.map */