.holiday {
    max-width: 1920px;
    margin: 0 auto;
    height: 100vh;
    max-height: 1080px;
}

.holiday__container {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}

.holiday__title {
    font-size: 75px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 1010px;
    text-align: center;
}

@media screen and (max-width: 1700px) {
    .holiday {
        height: 800px;
    }
}

@media screen and (max-width: 1300px) {
    .holiday {
        height: 650px;
    }
}

@media screen and (max-width: 1100px) {
    .holiday {
        height: 550px;
    }
}

@media screen and (max-width: 850px) {
    .holiday {
        height: 450px;
    }
}

@media screen and (max-width: 1100px) {
    .holiday__title {
        font-size: 65px;
    }
}

@media screen and (max-width: 1100px) {
    .holiday__button {
        width: 250px;
        font-size: 22px;
        height: 60px;
    }
}

@media screen and (max-width: 850px) {
    .holiday__title {
        font-size: 55px;
    }
}

@media screen and (max-width: 750px) {
    .holiday__title {
        font-size: 48px;
    }
}

@media screen and (max-width: 750px) {
    .holiday__button {
        width: 235px;
        font-size: 20px;
        height: 54px;
        margin-top: 10px;
    }
}


@media screen and (max-width: 650px) {
    .holiday {
        height: 350px;
    }
}


@media screen and (max-width: 650px) {
    .holiday__title {
        font-size: 40px;
    }
}

@media screen and (max-width: 650px) {
    .holiday__button {
        width: 220px;
        font-size: 18px;
        height: 50px;
    }
}


@media screen and (max-width: 550px) {
    .holiday__title {
        font-size: 35px;
    }
}

@media screen and (max-width: 550px) {
    .holiday__button {
        width: 210px;
        font-size: 18px;
        height: 45px;
    }
}


@media screen and (max-width: 500px) {
    .holiday {
        height: 290px;
    }
}


@media screen and (max-width: 450px) {
    .holiday__title {
        font-size: 30px;
    }
}

@media screen and (max-width: 450px) {
    .holiday__button {
        width: 200px;
        font-size: 17px;
        height: 40px;
    }
}


@media screen and (max-width: 400px) {
    .holiday {
        height: 250px;
    }
}


@media screen and (max-width: 400px) {
    .holiday__title {
        font-size: 25px;
    }
}

@media screen and (max-width: 400px) {
    .holiday__button {
        width: 175px;
        font-size: 16px;
        height: 35px;
    }
}
