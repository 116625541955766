.modal {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -500;
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 0;
}

.modal__container {
  background-color: #212121;
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  border-radius: 8px;
  box-shadow: 20px 20px 22px rgba(0, 0, 0, 0.2);
  color: #fff;
  flex-direction: column;
  height: 720px;
  margin: 0 auto;
  width: 900px;
  position: relative;
  overflow: hidden;
  transition: 0.5s ease-in-out;
  opacity: 0;
  margin-top: -800px;
}

.modal__container.active {
  opacity: 1;
  margin-top: 0;
}

.modal.active {
  opacity: 1;
  z-index: 100;
}

.modal__header {
  display: flex;
  padding: 20px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

.modal__close {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.modal__close:hover {
  color: #a0a0a0;
}

.modal__inner {
  display: flex;
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
}

.modal__subtitle {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}
.modal__subtitle span {
  background: linear-gradient(45deg, #e63732 33%, #e85931 66%, #f6410f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modal__form {
  width: 70%;
  position: relative;
  z-index: 1;
}
.modal__form input {
  width: 400px;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
  font-size: 15px;
}
.modal__form select {
  width: 400px;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 15px;
}
.modal__form li {
  margin-top: 20px;
}
.modal__form legend {
  margin-bottom: 10px;
  max-width: 300px;
}
@media screen and (max-width: 950px) {
  .modal__form legend {
    text-align: left;
  }
}
@media screen and (max-width: 550px) {
  .modal__form select {
    width: 300px;
  }
}
@media screen and (max-width: 550px) {
  .modal__form input {
    width: 300px;
  }
}
@media screen and (max-width: 420px) {
  .modal__form select {
    width: 280px;
  }
}
@media screen and (max-width: 420px) {
  .modal__form input {
    width: 280px;
  }
}
@media screen and (max-width: 650px) {
  .modal__form legend {
    display: none;
  }
}

.modal__button {
  margin-top: 20px;
  width: 400px;
}

.modal__personal-data {
  font-size: 10px;
  color: #c6c6c6;
  margin-top: 10px;
  max-width: 400px;
  text-align: center;
}
.modal__personal-data span {
  text-decoration: underline;
  cursor: pointer;
}

.modal__image {
  width: 800px;
  -o-object-fit: contain;
     object-fit: contain;
  position: absolute;
  left: 160px;
  top: 130px;
}

@media screen and (max-width: 950px) {
  .modal__image {
    display: none;
  }
}
@media screen and (max-width: 950px) {
  .modal__container {
    width: 600px;
  }
}
@media screen and (max-width: 650px) {
  .modal__container {
    width: 500px;
    height: 620px;
  }
}
@media screen and (max-width: 550px) {
  .modal__container {
    width: 400px;
  }
}
@media screen and (max-width: 950px) {
  .modal__inner {
    justify-content: center;
    width: 100%;
  }
}
@media screen and (max-width: 950px) {
  .modal__form {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
@media screen and (max-width: 550px) {
  .modal__button {
    width: 300px;
  }
}
@media screen and (max-width: 650px) {
  .modal__subtitle {
    font-size: 22px;
  }
}
@media screen and (max-width: 550px) {
  .modal__subtitle {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 420px) {
  .modal__container {
    width: 300px;
  }
}
@media screen and (max-width: 420px) {
  .modal__button {
    width: 250px;
    height: 50px;
    font-size: 20px;
  }
}
@media screen and (max-width: 620px) {
  .modal__header {
    font-size: 20px;
  }
}/*# sourceMappingURL=index.css.map */