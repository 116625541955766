.squares {

}

.squares__title {
    font-size: 74px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.squares__container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
}

.squares__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    color: #fff;
}

.squares__image {
    width: 50%;
    border-radius: 30px;
    object-fit: cover;
}

.squares__info {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.squares__item-title {
    font-size: 60px;
    font-weight: 600;
}

.squares__text {
    font-size: 30px;
    font-weight: 300;
    margin-top: 40px;
    margin-bottom: 40px;
    line-height: 40px;
}

.squares__image-order {
    order: 2;
}

.squares__item-square {
    font-size: 21px;
    margin-top: 10px;
    color: #818181;
}

@media screen and (max-width: 1760px) {
    .squares__text {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 1760px) {
    .squares__item {
        align-items: start;
    }
}


@media screen and (max-width: 1700px) {
    .squares__text {
        font-size: 28px;
    }
}

@media screen and (max-width: 1500px) {
    .squares__image {
        width: 45%;
    }
}

@media screen and (max-width: 1500px) {
    .squares__info {
        width: 53%;
    }
}

@media screen and (max-width: 1400px) {
    .squares__item {
        align-items: center;
        width: 90%;
    }
}

@media screen and (max-width: 1400px) {
    .squares__item {
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
}

@media screen and (max-width: 1400px) {
    .squares__image {
        width: 100%;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 1400px) {
    .squares__image-order {
        order: 0;
    }
}

@media screen and (max-width: 1400px) {
    .squares__info {
        width: 100%;
    }
}

@media screen and (max-width: 850px) {
    .squares__button {
        width: 200px;
        height: 50px;
        border-radius: 10px;
        font-size: 20px;
    }
}

@media screen and (max-width: 850px) {
    .squares__text {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 850px) {
    .squares__image {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 500px) {
    .squares__button {
        width: 150px;
        height: 40px;
        font-size: 16px;
    }
}

@media screen and (max-width: 700px) {
    .squares__item {
        margin-bottom: 50px;
    }
}












@media screen and (max-width: 1100px) {
    .squares__title {
        font-size: 65px;
    }
}


@media screen and (max-width: 850px) {
    .squares__title {
        font-size: 55px;
    }
}

@media screen and (max-width: 750px) {
    .squares__title {
        font-size: 48px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
}



@media screen and (max-width: 650px) {
    .squares__title {
        font-size: 40px;
    }
}


@media screen and (max-width: 550px) {
    .squares__title {
        font-size: 35px;
    }
}



@media screen and (max-width: 450px) {
    .squares__title {
        font-size: 30px;
    }
}


@media screen and (max-width: 400px) {
    .squares__title {
        font-size: 25px;
    }
}

@media screen and (max-width: 1300px) {
    .squares__item-title {
        font-size: 55px;
    }
}


@media screen and (max-width: 1050px) {
    .squares__item-title {
        font-size: 52px;
    }
}

@media screen and (max-width: 950px) {
    .squares__item-title {
        font-size: 48px;
    }
}



@media screen and (max-width: 850px) {
    .squares__item-title {
        font-size: 40px;
    }
}


@media screen and (max-width: 750px) {
    .squares__item-title {
        font-size: 35px;
    }
}



@media screen and (max-width: 650px) {
    .squares__item-title {
        font-size: 30px;
    }
}


@media screen and (max-width: 500px) {
    .squares__item-title {
        font-size: 25px;
    }
}

@media screen and (max-width: 1050px) {
    .squares__text {
        font-size: 20px;
        line-height: 30px;
    }
}


@media screen and (max-width: 850px) {
    .squares__text {
        font-size: 15px;
        line-height: 20px;
    }
}


@media screen and (max-width: 600px) {
    .squares__text {
        font-size: 13px;
    }
}

@media screen and (max-width: 700px) {
    .squares__item-square {
        font-size: 17px;
    }
}


