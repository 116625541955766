.main {
    max-width: 1920px;
    height: 100vh;
    margin: 0 auto;
}

.main__container {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}

.main__logo {
    width: 370px;
    object-fit: contain;
}

.main__title {
    font-size: 75px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 20px;
}

.main__text {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 20px;
    width: 80%;
    text-align: center;
}

.main__buttons {
    width: 50%;
    display: flex;
    justify-content: space-between;
}


@media screen and (max-width: 1100px) {
    .main__title {
        font-size: 65px;
    }
}

@media screen and (max-width: 1300px) {
    .main__button {
        width: 250px;
        font-size: 22px;
        height: 60px;
    }
}

@media screen and (max-width: 850px) {
    .main__title {
        font-size: 55px;
    }
}

@media screen and (max-width: 750px) {
    .main__title {
        font-size: 48px;
    }
}


@media screen and (max-width: 1150px) {
    .main__text{
        font-size: 30px;
    }
}

@media screen and (max-width: 850px) {
    .main__text {
        font-size: 25px;
    }
}

@media screen and (max-width: 650px) {
    .main__text {
        font-size: 20px;
    }
}

@media screen and (max-width: 750px) {
    .main__button {
        width: 235px;
        font-size: 20px;
        height: 54px;
        margin-top: 10px;
    }
}




@media screen and (max-width: 650px) {
    .main__title {
        font-size: 40px;
    }
}

@media screen and (max-width: 650px) {
    .main__button {
        width: 220px;
        font-size: 18px;
        height: 50px;
    }
}

@media screen and (max-width: 800px) {
    .main__logo {
        width: 250px;
    }
}

@media screen and (max-width: 1050px) {
    .main__buttons {
        width: 70%;
        justify-content: space-around;
    }
}

@media screen and (max-width: 730px) {
    .main__buttons {
        width: 100%;
        justify-content: space-around;
    }
}



@media screen and (max-width: 550px) {
    .main__title {
        font-size: 35px;
    }
}

@media screen and (max-width: 550px) {
    .main__button {
        width: 210px;
        font-size: 18px;
        height: 45px;
    }
}



@media screen and (max-width: 450px) {
    .main__title {
        font-size: 30px;
    }
}

@media screen and (max-width: 450px) {
    .main__button {
        width: 200px;
        font-size: 17px;
        height: 40px;
    }
}


@media screen and (max-width: 450px) {
    .main__buttons {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 400px) {
    .main__title {
        font-size: 25px;
    }
}

@media screen and (max-width: 400px) {
    .main__button {
        width: 175px;
        font-size: 16px;
        height: 35px;
    }
}
