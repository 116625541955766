.footer {
  background-color: #1a1a1a;
  padding-top: 100px;
  margin-top: 100px;
}

.footer__title {
  display: flex;
  justify-content: center;
  font-size: 74px;
  font-weight: 800;
  margin-bottom: 100px;
}

.footer__container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  color: #fff;
}

.footer__contacts {
  width: 40%;
}

.footer__contacts-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.footer__contacts-icon {
  width: 65px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-right: 10px;
}

.footer__contacts-text {
  font-size: 25px;
}

.footer__links {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.footer__link {
  width: 100px;
  height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
}

.footer__map {
  border-radius: 30px;
}

.footer__bottom {
  background-color: #000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #737373;
  font-size: 20px;
  height: 100px;
  margin-top: 100px;
}

.footer__bottom-text {
  width: 350px;
  text-align: center;
}

.footer__button {
  margin-top: 50px;
}

@media screen and (max-width: 1100px) {
  .footer__title {
    font-size: 65px;
  }
}
@media screen and (max-width: 850px) {
  .footer__title {
    font-size: 55px;
  }
}
@media screen and (max-width: 750px) {
  .footer__title {
    font-size: 48px;
  }
}
@media screen and (max-width: 650px) {
  .footer__title {
    font-size: 40px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 550px) {
  .footer__title {
    font-size: 35px;
  }
}
@media screen and (max-width: 450px) {
  .footer__title {
    font-size: 30px;
  }
}
@media screen and (max-width: 400px) {
  .footer__title {
    font-size: 25px;
  }
}
@media screen and (max-width: 850px) {
  .footer__contacts-text {
    font-size: 25px;
  }
}
@media screen and (max-width: 650px) {
  .footer__contacts-text {
    font-size: 20px;
  }
}
@media screen and (max-width: 400px) {
  .footer__contacts-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 650px) {
  .footer__contacts-icon {
    width: 60px;
  }
}
@media screen and (max-width: 400px) {
  .footer__contacts-icon {
    width: 50px;
  }
}
@media screen and (max-width: 1700px) {
  .footer__map {
    width: 55%;
  }
}
@media screen and (max-width: 1000px) {
  .footer__container {
    flex-direction: column;
  }
}
@media screen and (max-width: 1000px) {
  .footer__contacts {
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .footer__map {
    width: 100%;
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .footer {
    padding-top: 50px;
    margin-top: 50px;
  }
}
@media screen and (max-width: 1000px) {
  .footer__contacts {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 1000px) {
  .footer__links {
    align-self: center;
  }
}
@media screen and (max-width: 1000px) {
  .footer__button {
    align-self: center;
  }
}
@media screen and (max-width: 600px) {
  .footer__button {
    width: 200px;
    font-size: 20px;
    margin-top: 20px;
    height: 50px;
  }
}
@media screen and (max-width: 500px) {
  .footer__links {
    display: grid;
    grid-template-columns: repeat(2, 100px);
  }
}
@media screen and (max-width: 800px) {
  .footer__bottom-text {
    font-size: 15px;
  }
}
@media screen and (max-width: 600px) {
  .footer__bottom {
    margin-top: 50px;
  }
}/*# sourceMappingURL=index.css.map */