.modal-details__container {
  overflow: scroll;
}
.modal-details__container::-webkit-scrollbar {
  display: none;
}

.modal-details__inner {
  margin-bottom: 20px;
}

.modal-details {
  display: flex;
  justify-content: space-between;
}

.modal-details__image {
  width: 250px;
  height: 450px;
  border-radius: 20px;
  margin-right: 20px;
  -o-object-fit: cover;
     object-fit: cover;
}

.modal-details__title {
  font-size: 30px;
  font-weight: 700;
}

.modal-details__text {
  font-size: 20px;
  margin-top: 20px;
  font-weight: 400;
  line-height: 25px;
}

.modal-details__button {
  width: 230px;
  height: 60px;
  font-size: 20px;
  margin-top: 20px;
}

@media screen and (max-width: 950px) {
  .modal-details {
    width: 90%;
  }
}
@media screen and (max-width: 950px) {
  .modal-details__text {
    font-size: 17px;
  }
}
@media screen and (max-width: 950px) {
  .modal-details__button {
    font-size: 17px;
    width: 200px;
    height: 50px;
  }
}
@media screen and (max-width: 950px) {
  .modal-details__buttons {
    margin-left: 7px;
    margin-bottom: 7px;
  }
}
@media screen and (max-width: 620px) {
  .modal-details__buttons {
    margin-left: 0px;
  }
}
@media screen and (max-width: 950px) {
  .modal-details__image {
    width: 200px;
    height: 400px;
  }
}
@media screen and (max-width: 650px) {
  .modal-details__image {
    display: none;
  }
}
@media screen and (max-width: 620px) {
  .modal-details__title {
    font-size: 20px;
  }
}
@media screen and (max-width: 620px) {
  .modal-details__text {
    font-size: 15px;
  }
}
@media screen and (max-width: 620px) {
  .modal-details__button {
    font-size: 15px;
    width: 150px;
    height: 40px;
    border-radius: 15px;
  }
}
@media screen and (max-width: 620px) {
  .modal-details__buttons {
    display: flex;
    justify-content: center;
  }
}/*# sourceMappingURL=index.css.map */