@keyframes rotation {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
.info {
  padding: 100px;
}

.info__title {
  font-size: 24px;
  font-weight: 800;
  margin-top: 15px;
  margin-bottom: 15px;
}

.info__text {
  font-size: 18px;
  font-weight: 200;
}

.info__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  -moz-column-gap: 100px;
       column-gap: 100px;
  row-gap: 100px;
  align-items: start;
}

.info__more {
  height: 0;
  opacity: 0;
  transition: 0.3s ease-in-out;
  font-size: 16px;
  color: #989898;
}

.info__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.info__item:hover .info__image {
  animation: rotation 0.5s;
}
.info__item:hover .info__more {
  height: 30px;
  opacity: 1;
}

.info__image {
  width: 83px;
  -o-object-fit: contain;
     object-fit: contain;
}

@media screen and (max-width: 1450px) {
  .info__item {
    width: 435px;
  }
}
@media screen and (max-width: 1300px) {
  .info__item {
    width: 350px;
  }
}
@media screen and (max-width: 1100px) {
  .info__item {
    width: 300px;
  }
}
@media screen and (max-width: 1000px) {
  .info__item {
    width: 280px;
  }
}
@media screen and (max-width: 940px) {
  .info__item {
    width: 250px;
  }
}
@media screen and (max-width: 870px) {
  .info__item {
    width: 450px;
  }
}
@media screen and (max-width: 670px) {
  .info {
    padding: 50px;
  }
}
@media screen and (max-width: 670px) {
  .info__item {
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .info__title {
    font-size: 20px;
  }
}
@media screen and (max-width: 650px) {
  .info__text {
    font-size: 15px;
  }
}
@media screen and (max-width: 650px) {
  .info__image {
    width: 60px;
  }
}
@media screen and (max-width: 650px) {
  .info__container {
    -moz-column-gap: 50px;
         column-gap: 50px;
    row-gap: 50px;
  }
}
@media screen and (max-width: 500px) {
  .info {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media screen and (max-width: 400px) {
  .info__title {
    font-size: 18px;
  }
}
@media screen and (max-width: 400px) {
  .info__text {
    font-size: 13px;
  }
}
@media screen and (max-width: 400px) {
  .info__image {
    width: 50px;
  }
}
@media screen and (max-width: 600px) {
  .info__more {
    font-size: 14px;
  }
}/*# sourceMappingURL=index.css.map */