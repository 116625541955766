.modal-image {
    background-color: rgba(0, 0, 0, .3);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -500;
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: 0;
    cursor: pointer;
}

.modal-image__image {
    max-width: 80vw;
    max-height: 70vh;
    object-fit: cover;
    border-radius: 30px;
    transition: 0.5s ease-in-out;
    opacity: 0;
    margin-top: -800px;
    cursor: initial;
}

.modal-image.active {
    opacity: 1;
    z-index: 100;
}

.modal-image__image.active {
    opacity: 1;
    margin-top: 0;
}